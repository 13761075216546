import React, { useEffect, useState, useMemo } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { MoveDirection, OutMode } from '@tsparticles/engine';

const Banner: React.FC = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = useMemo(() => ({
    // Removed the background option to allow the image background to show
    fpsLimit: 60,
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "repulse",
        },
        onClick: {
          enable: true,
          mode: "push",
        },
        resize: {
          enable: true,
          delay: 0.5,
        },
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#00FE00",
      },
      links: {
        color: "#00FE00",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        enable: true,
        speed: 2,
        direction: MoveDirection.none,
        outModes: {
          default: OutMode.bounce,
        },
        random: false,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  }), []);

  if (!init) {
    return null;
  }

  return (
    <section id="top-banner" style={{ position: 'relative', overflow: 'hidden' }}>
      <Particles
        id="tsparticles"
        options={options}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      />
      <Container className="container-custom" style={{ position: 'relative', zIndex: 2 }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography variant="h1" className="banner-heading">
              Khronic AI
            </Typography>
            <Typography variant="body1" className="banner-text mb-4">
              Leveraging advanced machine learning and natural language processing, we offer cutting-edge AI solutions tailored to the unique needs of the cannabis industry.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Banner;
